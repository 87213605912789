// React
import React from 'react';
import Helmet from 'react-helmet';

// Gatsby
import { Link, graphql } from 'gatsby';
// import Img from 'gatsby-image';

// Third-party
import get from 'lodash/get';

// Core components
import Layout from '../components/layout';
//import heroStyles from '../components/hero.module.css';

class EventTemplate extends React.Component {
  render() {
    const track = get(this.props, 'data.contentfulTrack');
    const siteTitle = get(this.props, 'data.site.siteMetadata.title');

    return (
      <Layout location={this.props.location}>
        <div style={{ background: '#fff', color: '#373f49' }}>
          <Helmet title={`${track.name} | ${siteTitle}`} />

          {/* <div className={heroStyles.hero}>
            {track.name} */}
          {/* <Img className={heroStyles.heroImage} alt={track.name} fluid={post.heroImage.fluid} /> */}
          {/* </div> */}
          <div className="wrapper">
            <h1 className="section-headline">{track.name}</h1>
            <table>
              <tr>
                <td style={{ width: '100px' }}>Address:</td>
                <td>{track.address}</td>
              </tr>
              <tr>
                <td>City:</td>
                <td>{track.city}</td>
              </tr>
              <tr>
                <td>State:</td>
                <td>{track.state}</td>
              </tr>
              <tr>
                <td>Zip Code:</td>
                <td>{track.zipCode}</td>
              </tr>
              <tr>
                <td>Website:</td>
                <td>
                  <a href={track.websiteUrl.websiteUrl} target="_new">
                    {track.websiteUrl.websiteUrl}
                  </a>
                </td>
              </tr>
              <tr>
                <td>Altitude:</td>
                <td>{track.altitude} feet</td>
              </tr>
            </table>
          </div>
        </div>
      </Layout>
    );
  }
}

export default EventTemplate;

export const pageQuery = graphql`
  query TrackBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulTrack(slug: { eq: $slug }) {
      name
      address
      city
      state
      zipCode
      websiteUrl {
        websiteUrl
      }
      altitude
      location {
        lon
        lat
      }
    }
  }
`;
